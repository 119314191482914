<template>
  <!-- Modal -->
  <div class="modal" id="pdfModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">{{ pdfFile.adj_nmbre }}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div v-if="!pdfFile.ocr">
            <iframe 
              id="iframe"
              v-bind:src="pdfFile.url"
              style="width:100%; height:500px; border:2px solid;">
            </iframe>
          </div>
          <div v-else>
            <img src="/images/wait.gif">
          </div>
          <div v-if="pdfFile.ocr" style="width:100%; height:500px;border:2px solid;overflow:scroll">
            <div v-for="(i, index) in pdfFile.adj_ocr">
              <p>{{ i.id }}</p>
              <div v-html="i.page.replace(/(?:\r\n|\r|\n)/g, '<br>')" style="font-size:10px"></div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" v-on:click="tabPdf" class="btn btn-success" data-dismiss="modal">Pantalla completa</button>
          <button type="button" v-if="pdfFile.xocr" v-on:click="setOcr" class="btn btn-success">{{ pdfFile.ocr ? "Pdf" : "Ocr" }}</button>
          <button type="button" v-on:click="clearPdf" class="btn btn-success" data-dismiss="modal">Regresar</button>
        </div> 
      </div>
    </div>
  </div>
</template>

<script>
//import PDFObject from 'pdfobject';

export default {
  props: ['pdfFile'],
  data() {
    return {
      //ocr: false,
    }
  },
  watch: {

  },
  created: function () {
    //document.oncontextmenu = function() { return false; }
  },
  mounted: function () {
    //PDFObject.embed(this.pdfFile.url, this.$refs.pdfcontainer, { width: "80%", height: "600px" });

  },
  unmounted: function () {
    console.log('Unmounted');
  },
  methods: {
    clearPdf() {
      this.pdfFile.url = '';
    },
    tabPdf() {
      if (this.pdfFile.systemFile) {
        this.$emit('view-file-system-provider')
      } else {
        const link = document.createElement('a');
        link.setAttribute('href', this.pdfFile.url);
        //link.setAttribute('download', adj_nmbre);
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(link);
        document.body.removeChild(link);
      }
    },
    setOcr() {
      this.pdfFile.ocr = !this.pdfFile.ocr;
    },
    mouseDown() {
      alert('siged');
    }
  }
}
</script>

<style>
/*
.modal-dialog {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width:800px;
    height:300px;
}
*/
/*
.pdfobject-container { height: 600px; width: 600px; border: 1rem solid rgba(0,0,0,.1); }
*/
</style>
